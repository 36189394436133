@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
   .ui-title-xl {
      @apply text-3xl md:text-4xl tracking-tight text-black font-[650];
   }

   /* Communities */
   .communities-content-wrapper {
      @apply w-full max-w-[900px] xl:max-w-[1400px] mx-auto px-12 md:px-20;
   }
 }

 @layer utilities {
   .line-color {
      border-color: var(--primaryLineColor);
   }

   .text-balance {
      text-wrap: balance;
   }
 }

 /* .wrapper {
   position: relative;
   display: flex;
   flex-direction: column;
   align-items: center;
   width: fit-content;
 } */
 
 .button {
   display: flex;
   /* height: 34px; */
   align-items: center;
   gap: 8px;
   border-radius: 100%;
   /* padding: 16px; */
   font-size: 14px;
   font-weight: 500;
   text-decoration: none;
 }
 
 .clip-path-container {
   position: absolute;
   z-index: 10;
   width: 100%;
   overflow: hidden;
   transition: clip-path 0.25s ease;
   clip-path: inset(0px 75% 0px 0% round 17px);
 }
