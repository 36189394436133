.weight-medium {
   font-weight: 500 !important;
}

.weight-semibold {
   font-weight: 600 !important;
}

.ui-link {
   color: var(--linkColor);
   .weight-medium;
}

.ui-paragraph-text {
   font-size: var(--paragraphTextSize);
   font-family: var(--paragraphTextFontFamily);
   font-weight: 450;
   line-height: var(--paragraphTextLineHeight);
   color: var(--paragraphTextColor);
}

.ui-title-xxxl {
   font-size: 64px;
   line-height: 64px;
   font-weight: 550;
   letter-spacing: -1.7px;
   color: var(--titleTextColor);
   word-wrap: break-word;
   font-family: var(--titleFontFamily);

   @media screen and (max-width: 1350px) {
      font-size: 40px;
      line-height: 40px;
      letter-spacing: -1px;
   }
}

.ui-title-xxl {
   font-size: 50px;
   line-height: 56px;
   font-weight: 550;
   letter-spacing: -1.8px;
   color: var(--titleTextColor);
   word-wrap: break-word;
   font-family: var(--titleFontFamily);

   @media screen and (max-width: 1350px) {
      font-size: 40px;
      line-height: 40px;
      letter-spacing: -1px;
   }

   // @media (min-width: 1350px) and (max-width: 1700px) {
   //    font-size: 44px;
   //    line-height: 48px;
   //    letter-spacing: -1.4px;
   // }
}

// .ui-title-xl {
//    font-size: 40px;
//    line-height: 48px;
//    font-weight: var(--titleXlFontWeight);
//    color: var(--titleTextColor);
//    word-wrap: break-word;
//    font-family: var(--titleFontFamily);
//    letter-spacing: -1.4px;

//    @media screen and (max-width: 675px) {
//       font-size: 32px;
//       line-height: 36px;
//       letter-spacing: -0.7px;
//    }
// }

.ui-title-lg {
   font-size: 24px;
   line-height: 32px;
   color: var(--titleTextColor);
   word-wrap: break-word;
   font-family: var(--titleFontFamily);
   letter-spacing: -0.7px; 
   font-weight: 650;
}

.ui-title-md {
   font-size: 18px;
   line-height: 24px;
   letter-spacing: 0px;
   color: var(--titleTextColor);
   font-family: var(--titleFontFamily);
   letter-spacing: -0.2px;
   font-weight: 650;
}

.ui-title-sm {
   font-size: 15px;
   line-height: 24px;
   font-weight: 520;
   color: var(--titleTextColor);
   font-family: var(--titleFontFamily);
   // letter-spacing: -0.1px;
}

.ui-title-xs {
   font-size: inherit;
   line-height: inherit;
   font-weight: 550;
   color: var(--titleTextColor);
   font-family: var(--titleFontFamily);
   letter-spacing: -0.2px;
}

.ui-subtitle {
   font-size: 20px;
   line-height: var(--subtitleLineHeight);
   font-weight: 400;
   letter-spacing: -0.2px;
   color: var(--subtitleTextColor);
}

.ui-subtitle-small {
   font-size: var(--subtitleSmallTextSize);
   line-height: var(--subtitleSmallLineHeight);
   font-weight: 400;
   color: var(--subtitleTextColor);
}

.ui-meta-text-lg {
   font-size: 15px;
   line-height: 20px;
}

.ui-meta-text {
   font-size: var(--metaTextSize);
   line-height: 20px;
   color: var(--metaTextColor);
   white-space: wrap;
   word-wrap: break-word;
   font-family: 'Inter', 'switzer', -apple-system, BlinkMacSystemFont, sans-serif;
   letter-spacing: -0.2px;

   b {
      font-weight: 500;
   }
}

@font-face {
   font-family: 'replica-mono';
   src: url('/assets/fonts/replica-mono/replica_mono_bold.woff') format('woff'),
      url('/assets/fonts/replica-mono/replica_mono_bold.ttf') format('truetype');
   font-weight: 700;
   font-style: normal;
}

@font-face {
   font-family: 'switzer';
   src: url('/assets/fonts/switzer/switzer.ttf') format('truetype'),
      url('/assets/fonts/switzer/switzer.woff2') format('woff2 supports variations'),
      url('/assets/fonts/switzer/switzer.woff2') format('woff2-variations');
   font-weight: 100 1000;
}

@font-face {
   font-family: 'inter';
   src: url('/assets/fonts/inter/inter.ttf') format('truetype');
   font-weight: 100 1000;
} 
